<template>
	<div>
		<dd-card class="box-card" :title="'1. Setari generale'" :collapsed.sync="steps.one.collapsed"
			@toggledCard="toggledCard">
			<Box :type="'error'" :active="true" v-if="validationErrors.length > 0">
				<div slot="box-body">
					<ul class="rawUl">
						<li v-for="(error, index) in validationErrors" :key="index">{{error}}</li>
					</ul>
				</div>
			</Box>
			<el-form :model="general" :rules="rules" ref="general">
				<el-form-item prop="name" label="Nume feed">
					<el-input type="text" change size="small" v-model="general.feed_name"></el-input>
				</el-form-item>
				<el-form-item prop="type" label="Tip feed">
					<el-select v-model="general.feed_type" class="fullWidth" placeholder="Alege o optiune">
						<el-option v-for="type in feedTypes" :key="type.value" :label="type.label" :value="type.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="type" label="Delimitator coloane" v-if="general.feed_type === 0">
					<el-select v-model="general.separator" class="fullWidth" placeholder="Automat">
						<el-option v-for="delimiter in delimiters" :key="delimiter.value" :label="delimiter.label"
							:value="delimiter.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="model" label="Model feed">
					<el-select v-model="general.import_type" @change="changeImportType" class="fullWidth"
						placeholder="Alege o optiune">
						<el-option v-for="type in feedModels" :key="type.value" :label="type.label" :value="type.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="force_insert" label="Adauga prefix la produse" v-if="addProductMode">
					<div class="feed-step-1 subtitle">Daca alegeti sa nu bifati optiunea, produsele care au codul
						produsului deja in
						baza de date, dar au categoria si producatorul diferite nu vor fi importate.</div>
					<el-checkbox :checked="!!general.force_insert" class="fullWidth" v-model="general.force_insert"
						label="Permite adaugarea de prefix la codul produsului pentru produsele ce exista pe site dar au categorie si producator diferit"
						border></el-checkbox>
				</el-form-item>
				<el-form-item prop="tva_included" label="TVA">
					<el-checkbox :checked="!!general.tva_included" class="fullWidth" v-model="general.tva_included"
						label="Pretul are TVA inclus" border>
					</el-checkbox>
				</el-form-item>
				<el-form-item prop="url" label="URL feed">
					<el-input v-model="general.feed_url">
						<!--<el-button slot="append" type="primary" icon="el-icon-check" @click="validateLink">Valideaza
						</el-button>-->
					</el-input>
				</el-form-item>
				<el-form-item prop="defaultProcess" label="Timp de procesare implicit (zile)">
					<el-input-number class="fullWidth" v-model="general.default_runtime" :min="1" :max="7">
					</el-input-number>
				</el-form-item>
			</el-form>
			<el-button :loading="isLoading" type="primary" class="fullWidth" @click="validateFeedGeneralSettings">
				Salveaza</el-button>
		</dd-card>
	</div>

</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	const DDCard = () => import('@/components/DDCard');
	import {
		EventBus
	} from '@/utils/Bus.js';
	import {
		mapGetters,
		mapState
	} from 'vuex';
	export default {
		name: 'feed-step-1',
		components: {
			'dd-card': DDCard,
			Box
		},
		data() {
			return {
				validationErrors: [],
				general: {
					feed_name: '',
					feed_type: '',
					default_runtime: 3,
					feed_url: '',
					import_type: '',
					force_insert: false,
					tva_included: false
				},
				feedTypes: [{
						label: 'CSV',
						value: 0
					},
					{
						label: 'XLS',
						value: 1
					},
					{
						label: 'XML',
						value: 2,
					}
				],
				delimiters: [{
						label: 'Automat',
						value: ''
					},
					{
						label: '\\t',
						value: '\\t'
					}, {
						label: ';',
						value: ';'
					}, {
						label: '|',
						value: '|'
					}, {
            label: '#',
            value: '#'
          }, {
            label: ',',
            value: ','
          }
				],
				feedModels: [{
						label: 'Publicare produse',
						value: 1
					},
					{
						label: 'Actualizare pret si stoc',
						value: 0
					}
				],
				rules: {
					feed_type: [{
						required: true,
						message: 'Tipul feedului este obligatoriu',
						trigger: 'change'
					}],
					delimiter: {
						required: false
					},
					import_type: [{
						required: true,
						message: 'Modelul feedului este obligatoriu',
						trigger: 'change'
					}],
					feed_name: [{
							required: true,
							message: 'Numele feedului este obligatoriu',
							trigger: 'blur'
						},
						{
							min: 5,
							message: 'Numele trebuie sa contina cel putin 5 caractere',
							trigger: 'blur'
						}
					],
					feed_url: [{
						required: true,
						message: 'URL-ul feedului este obligatoriu',
						trigger: 'blur'
					}],
					default_runtime: [{
						required: true,
						message: 'Timpul de procesare trebuie sa fie minim 1 zi',
						trigger: 'blur'
					}]
				},
			};
		},
		methods: {
			toggledCard(status) {
				this.$store.commit('feeds/TOGGLE_COLLAPSE', {
					status,
					step: 'one'
				});
			},
			async validateLink() {
				this.validationErrors = [];
				if (this.general.feed_url === '' || this.general.feed_url == 0 || this.general.feed_url ===
					'undefined') {
					this.sbMsg({
						type: 'warn',
						message: 'URL feed este gol'
					});
					return false;
				}

				const res = await this.$store.dispatch('feeds/ValidateLink', this.general.feed_url);
				if (typeof res.message.status === 'undefined') {
					this.validationErrors.push(
						'"Eroare generala: Link invalid. Verificati daca linkul este public si poate fi accesat.');
					return false;
				} else if (parseInt(res.message.status) === 0) {
					if (typeof res.message.errors === 'undefined' || res.message.errors.length < 1) {
						this.validationErrors.push(
							'Eroare generala: Link invalid. Verificati daca linkul este public si poate fi accesat.'
						);
						return false;
					} else {
						this.validationErrors = res.message.errors;
					}
				}
				if (this.validationErrors.length > 0) {
					this.sbMsg({
						type: 'warn',
						message: 'Au aparut erori la validarea linkului. Verificati cauzele la inceputul paginii'
					});
					return false;
				}
				this.sbMsg({
					type: 'warn',
					message: 'Linkul catre feed a fost validat'
				});
				return true;
			},
			async validateFeedGeneralSettings() {
				this.validationErrors = [];
				this.$refs['general'].validate(async (valid) => {
					if (valid) {
						const res = await this.$store.dispatch('feeds/ValidateDetails', this.general);

						if (typeof res.message === 'undefined') {
							this.sbMsg({
								type: 'warn',
								message: 'Eroare generala: Contiutul feedului este invalid. Verificati ca tipul feedului sa reflecteze continutul din feed.'
							});
							return false;
						} else if (typeof res.message.status !== 'undefined' && parseInt(res.message
								.status) === 0) {
							if (typeof res.message.errors === 'undefined' || res.message.errors.length <
								1) {
								this.validationErrors.push(
									'Eroare generala: Contiutul feedului este invalid. Verificati ca tipul feedului sa reflecteze continutul din feed.'
								);
								return false;
							} else {
								this.validationErrors = res.message.errors;
							}
						}
						if (this.validationErrors.length > 0) {
							this.sbMsg({
								type: 'warn',
								message: 'Au aparut erori la validarea feedului. Verificati cauzele la inceputul paginii.'
							});
							this.$store.commit('feeds/DISABLE_STEP', 'two');
							this.$store.commit('feeds/TOGGLE_COLLAPSE', {
								status: false,
								step: 'two'
							});
							return false;
						}
						this.sbMsg({
							type: 'success',
							message: 'Datele feedului au fost validate. Se parseaza feedul pentru asociere...'
						});
						EventBus.$emit('feedValidated', res.message.id);
						this.$store.commit('feeds/SET_FEED_VALIDATED', true);
						return true;
					} else {
						return false;
					}
				});
			},
			populateFields() {
				for (const n in this.feedDetails) {
					if (typeof this.general[n] !== 'undefined') this.general[n] = this.feedDetails[n];
				}
				if (this.feedStep >= 1) {
					this.$store.commit('feeds/SET_STEP_FINISHED', 'one');
				}
			},
			changeImportType(val) {
				this.$store.commit('feeds/SET_IMPORT_TYPE', val);
			}
		},
		computed: {
			...mapState({
				steps: (state) => state.feeds.steps
			}),
			...mapGetters({
				'feedDetails': 'feeds/feedDetails',
				'feedId': 'feeds/feedId',
				'feedStep': 'feeds/feedStep',
				'isLoading': 'feeds/isLoading'
			}),
			addProductMode() {
				return typeof this.general.import_type !== 'undefined' && parseInt(this.general.import_type) === 1;
			}
		},
		watch: {},
		created() {
			if (this.feedId !== '') this.populateFields();
		},
		beforeDestroy() {
			EventBus.$off();
		}
	};
</script>

<style lang="scss" scoped>
	.feed-step-1.subtitle {
		font-size: 12px;
		display: inline-block;
		width: 100%;
		color: #606266;
	}
</style>